<template>
  <el-select :value="value" @change="v=>{this.$emit('input', v)}" placeholder="请选择" clearable>
    <el-option v-for="item in list" :key="item.id" :value="item.id" :label="item.name">
      <span style="float: left;">{{ item.name }}</span>
      <span class="code">{{ item.flag }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "OperatorSelect",
  props: ["value"],
  data() {
    return {};
  },
  computed: {
    list() {
      let res = [];
      this.$store.state.baseinfo.Operator.forEach((item) => {
        let flag = item.isAdmin ? "管理员" : "操作员";
        if (item.isStop) {
          flag = "已停用";
        }

        res.push({
          id: item.id,
          name: item.name,
          flag: flag,
        });
      });
      return res;
    },
  },
};
</script>

<style scoped>
.code {
  float: right;
  color: #999;
  font-size: 12px;
  margin-right: 20px;
}
</style>